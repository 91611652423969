<template>
    <div>
        <FilterModal
            @reset="resetFilter"
            @filter="getFilterData"
            :isLoading="isLoading"
        >
            <template v-slot:filters>
                <AccountRoleFilter
                    ref="accountRoleFilter"
                    :titleTypeOptions="['17', '325']"
                    v-model:titleType="filterPayload.titleType"
                    v-model:manCode="filterPayload.manCode"
                    v-model:role="filterPayload.role"
                    v-model:bgCode="filterPayload.bgCode"
                />
                <Dropdown
                    name="dataType"
                    :required="true"
                    :title="filterTitles.dataType"
                    :options="filterOptions.dataType"
                    v-model="filterPayload.dataType"
                />
                <Textbox
                    v-if="filterPayload.dataType === '1'"
                    name="receiveEndDate"
                    type="date"
                    max="today"
                    :min="minEndDate"
                    :required="true"
                    :title="filterTitles.receiveEndDate"
                    v-model="filterPayload.endDate"
                />
                <Textbox
                    v-else
                    name="ssEndDate"
                    type="date"
                    max="today"
                    :min="minEndDate"
                    :required="true"
                    :onlyMonth="true"
                    :isMonthLastDay="true"
                    :title="filterTitles.ssEndDate"
                    v-model="filterPayload.endDate"
                />
                <Dropdown
                    name="relationMode"
                    :required="true"
                    :title="filterTitles.relationMode"
                    :options="filterOptions.relationMode"
                    v-model="filterPayload.relationMode"
                />
            </template>
        </FilterModal>
    </div>
</template>
<script>
import _ from 'lodash'
import Textbox from '@/components/Textbox.vue'
import Dropdown from '@/components/Dropdown.vue'
import FilterModal from '@/containers/FilterModal.vue'
import AccountRoleFilter from '@/containers/AccountRoleFilter.vue'
import { annualFilterTitles } from '@/assets/javascripts/report/setting'
import { getReportAnnualCodesAPI } from '@/assets/javascripts/api'
import filterDefault from '@/assets/json/filterDefault.json'

export default {
    name: 'AnnualFilter',
    emits: [
        'show-data-table',
        'update:dropdownOptionsProp',
        'update:filterPayloadProp',
        'update:filterLogProp'
    ],
    components: {
        Textbox,
        Dropdown,
        FilterModal,
        AccountRoleFilter
    },
    props: {
        dropdownOptionsProp: {
            type: Object,
            default: function () {
                return { dataType: [], performanceSource: [], relationMode: [] }
            }
        },
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {
                    role: '',
                    manCode: '',
                    bgCode: '',
                    dateType: '',
                    startDate: '',
                    endDate: '',
                    relationMode: '',
                    supplierCode: '',
                    payWay: ''
                }
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getFilterData: function () {
            if (
                !this.$isSameValue(
                    this.perviousFilterPayload,
                    this.filterPayload
                )
            ) {
                this.perviousFilterPayload = this.$cloneDeepValue(
                    this.filterPayload
                )
                this.$emit('show-data-table')
            }
            this.filterLog = this.$getFilterLog(this.filterPayload)
            this.filterLog.endDate.title = this.endDateTitle
            this.filterLog.endDate.label = this.endDateLabel
        },
        resetFilter: function () {
            this.filterPayload = {
                endDate: this.$formatDate(this.$getDate()),
                manCode: this.filterPayload?.manCode || '',
                dataType: this.filterOptions.dataType[0]?.value || '',
                relationMode: this.filterOptions.relationMode[0]?.value || '',
                bgCode: this.filterPayload?.bgCode || '',
                ...filterDefault.annual
            }
            // includes mType, manCode(payload), role, bgCode(payload)
            this.$refs.accountRoleFilter.resetFilter()
        },
        getDropdownOptions: async function () {
            this.$showLoading()
            try {
                const res = await getReportAnnualCodesAPI()
                this.dropdownOptions = this.$transformInputType(res.data)
            } catch (error) {
                this.dropdownOptions = {}
                this.$showErrorMessage(
                    '載入時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                this.$hideLoading()
            }
        }
    },
    computed: {
        filterOptions: function () {
            return {
                dataType: _.uniqBy(
                    this.dropdownOptions.dataType?.map((data) => {
                        return {
                            label: data.value,
                            value: data.key.toString()
                        }
                    }),
                    (option) => option.value
                ),
                relationMode: _.uniqBy(
                    this.dropdownOptions.relationMode?.map((data) => {
                        return {
                            label: data.value,
                            value: data.key.toString()
                        }
                    }),
                    (option) => option.value
                )
            }
        },
        endDateTitle: function () {
            const receive = this.filterTitles.receiveEndDate
            const ss = this.filterTitles.ssEndDate
            return this.filterPayload.dataType === '1' ? receive : ss
        },
        endDateLabel: function () {
            /* 核實 查詢日改查詢月，值需把日trim掉 */
            const date = this.filterPayload.endDate
            const period = date.slice(0, 7)
            return this.filterPayload.dataType === '1' ? date : period
        }
    },
    watch: {
        dropdownOptions: {
            handler() {
                this.$emit('update:dropdownOptionsProp', this.dropdownOptions)
            },
            deep: true
        },
        filterPayload: {
            handler() {
                this.$emit('update:filterPayloadProp', this.filterPayload)
            },
            deep: true
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        }
    },
    data() {
        return {
            allOption: [
                {
                    label: '全部',
                    value: ''
                }
            ],
            dropdownOptions: this.dropdownOptionsProp,

            filterTitles: {},
            perviousFilterPayload: {},
            filterPayload: this.filterPayloadProp,
            filterLog: this.filterLogProp,
            // 根據公文0001鎖最早到2018-01-01
            minEndDate: '2018-01-01'
        }
    },
    async mounted() {
        this.$nextTick(() => {
            const accountRoleTitles = this.$refs.accountRoleFilter.filterTitles
            this.filterTitles = {
                ...accountRoleTitles,
                ...annualFilterTitles,
                _seq: [...accountRoleTitles._seq, ...annualFilterTitles._seq]
            }
        })
        await this.getDropdownOptions()
        await this.resetFilter()
        // this.getFilterData()
    }
}
</script>
<style lang="scss" scoped>
.source-dropdown {
    :deep(.form-label) {
        span:last-child {
            &::after {
                content: ' (限業務主管使用)';
                font-size: 12px;
                color: $primary-red;
            }
        }
    }
}
</style>
